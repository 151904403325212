import React, { useEffect, useRef } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import axios from 'axios';

const BarcodeScanner = ({ onScanSuccess }) => {
  const scannerRef = useRef(null);

  useEffect(() => {
    const scanner = new Html5QrcodeScanner(
      scannerRef.current.id,
      false
    );

    scanner.render(handleScanSuccess, handleScanFailure);

    return () => {
      scanner.clear().catch((error) => console.error('Failed to clear scanner. Error:', error));
    };
  }, []);



  // useEffect(() => {
  //   if (!showInput) {
  //     const scanner = new Html5QrcodeScanner(
  //       'reader',
  //       { fps: 10, qrbox: { width: 250, height: 250 }, aspectRatio: 1.333, disableFlip: false },
  //       false
  //     );

  //     scanner.render(handleScanSuccess);

  //     return () => {
  //       scanner.clear();
  //     };
  //   }
  // }, [showInput]);







  const handleScanSuccess = async (decodedText) => {
    try {

      onScanSuccess(decodedText);

    } catch (error) {
      console.error('Error verifying barcode:', error);
    }
  };

  const handleScanFailure = (error) => {
    console.warn(`Code scan error: ${error}`);
  };

  return <div id="barcode-scanner" ref={scannerRef}></div>;
};

export default BarcodeScanner;
