// src/App.js
import React, { useState } from 'react';
import BarcodeScanner from './BarcodeScanner';
import axios from 'axios';
// import './mockApi';

function App() {
  const [scannedData, setScannedData] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [message, setMessage] = useState('');
  const [promotion, setPromotion] = useState(null);
  const [inputPoints, setInputPoints] = useState(0);
  const handleScanSuccess = async (data) => {
    try {
      console.log(data);

      const response = await axios.get(`https://pro.koolfood.tn/web/?api=cmdpromotion&id=scan&code=${data}`);





      if (response.data.master.success != true) {
        setMessage(response.data.master.msg);
        alert(response.data.master.msg);

        return
      }

      setPromotion(response.data.master);
      console.log(response.data.master);
      setScannedData(data);
      setShowInput(true);
      // alert('Barcode scanned successfully. Please enter additional info.');
    } catch (error) {
      console.log('Error submitting info:', error);
      setMessage('Error submitting info.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get(`https://pro.koolfood.tn/web/?api=cmdpromotion&id=${inputValue}&code=${scannedData}&points=${inputPoints}`);


      console.log(response.data.master.msg);

      // alert(response.data.master.msg);
      if (response.data.master.msg != null) {
        setMessage(response.data.master.msg);
      }
      // if (response.data.master.msg != null) {
      //   setMessage(response.data.master.msg);
      // }
      //   setMessage('Error: Invalid data.');
      // }
    } catch (error) {
      console.log('Error submitting info:', error);
      setMessage('Error submitting info.');
    }
  };





  const handleScan = async (event) => {
    try {
      const response = await axios.get(`https://pro.koolfood.tn/web/?api=cmdpromotion&id=${inputValue}&code=${scannedData}`);


      console.log(response.data);



      if (response.data.master.msg != null) {
        setMessage(response.data.master.msg);
      }
      // //   setMessage('Error: Invalid data.');
      // }
    } catch (error) {
      console.log('Error submitting info:', error);
      setMessage('Error submitting info.');
    }
  };









  return (
    <div className=" flex-1 items-center justify-center  bg-gray-100 p-4">
      <h1 className="text-3xl font-bold mb-4">koolfood Promo</h1>
      {!showInput && <div className="flex-1"><BarcodeScanner onScanSuccess={scannedData ? null : handleScanSuccess} /></div>}
      {promotion && (  // Check if promotion data is not null
        <div className="mt-4">
          <p className="text-lg font-bold">Détails de promotion:</p>
          <p>Nom: {promotion.name}</p>
          <p>Valeur: {promotion.value}</p>
          <p>Code: {promotion.code.code}</p>
          <p>Points: {promotion.code.points}</p>
        </div>
      )}
      {showInput && (
        <form onSubmit={(event) => handleSubmit(event)} className="mt-4 justify-between flex-1 gap-x-2">
          {promotion && (  // Check if promotion exists
            <div className="mb-2">
              <label htmlFor="points" className="block text-lg font-bold">Points (Max: {promotion.code.points}):</label>
              <input
                type="number"
                id="points"
                value={inputPoints}
                onChange={(e) => setInputPoints(e.target.value)}
                placeholder="Enter points"
                className="border border-gray-300 p-2 rounded mb-2"
                max={promotion.code.points}  // Set maximum value to promotion points
                min={0}  // Set maximum value to promotion points
                required
              />
            </div>
          )}

          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Enter additional info"
            className="border border-gray-300 p-2 rounded mb-2"
          />
          <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
            Submit
          </button>
          <button type="button" className="bg-red-500 text-white px-4 py-2 rounded"
            //  onClick={() => { setShowInput(false); setPromotion(null); setInputValue(''); setInputPoints(0), setMessage(''); setScannedData('') }}
            onClick={() => {
              setShowInput(false);
              setPromotion(null);
              setInputValue('');
              setInputPoints(0);
              setMessage('');
              setScannedData('');
            }}
          >
            réinitialiser
          </button>
        </form>
      )}
      {message && <p className="mt-4 text-lg font-bold">{message}</p>}
    </div>
  );
}

export default App;
